class StyledText extends Component {
    constructor(el) {
        super(el);
        this.tables = this.$findAll('table');
        this.addEventListeners();
    }

    createWrapEl(elem) {
        const wrapper = document.createElement('div');
        wrapper.classList.add('u-responsiveTable');
        elem.parentNode.insertBefore(wrapper, elem);
        wrapper.appendChild(elem);
    }

    addEventListeners() {
        document.addEventListener("DOMContentLoaded", () => {
            this.tables.forEach(table => this.createWrapEl(table));
        });
    }
}
